.app {
  height: 100%;
  width: 100%;

  .topBanner {
    background-color: #2a292e;
    color: #fff;
    padding: 20px 0px 16px;

    .bannerContent {
      height: 100%;
      max-width: 1500px;
      padding: 0px 34px;
      margin: 0px auto;
      .bannerSec {
        width: 50%;
        padding: 0px 20px;

        &:nth-child(1) {
          border-right: 2px solid #fff;
        }

        .offerDetails {
          line-height: 24px;
          max-width: 500px;
          height: 0px;
          opacity: 0;
          transition: all 0.05s;

          &.open {
            height: initial;
            opacity: 1;
            transition: all 0.2s;
          }
        }

        h5 {
        }

        .gsButton {
          background: none;
          border: none;
          color: #ffffff;
          background-color: rgba(255, 255, 255, 0.253);
          padding: 10px 24px;
          border-radius: 4px;
          letter-spacing: 0.75px;
          text-decoration: none;
          transition: all 0.25s;

          &:hover {
            transform: scale(1.05);
            background-color: #30be76;
          }
        }
      }
    }

    .showMore {
      cursor: pointer;
      height: 20px;
      width: 20px;
      background-color: transparent;
      align-self: center;
      margin: 26px auto 0px;
      transform: rotate(45deg);
      border: 2px solid #fff;
      border-width: 0px 2px 2px 0px;
      transition: all 0.35s;

      &.open {
        transform: rotate(222deg);
      }
    }
  }
  .header {
    height: 100px;

    .headerContent {
      height: 100%;
      max-width: 1500px;
      padding: 0px 34px;
      margin: 0px auto;

      a {
        border: none;
        height: 58px;
        width: 166px;
        border-radius: 4px;
        background: none;
        color: #1f2041;
        font-size: 22px;
        text-decoration: none;
      }

      .fullButton {
        background-color: #30be76;
        color: #fff;
        height: 58px;
        width: 166px;
        border-radius: 4px;
        font-size: 18px;
        text-decoration: none;
      }
    }
  }

  .landing {
    height: 732px;
    background-color: #30be76;

    .landingContent {
      height: 100%;
      max-width: 1500px;
      padding: 0px 34px;
      margin: 0px auto;

      .leftSec {
        width: 50%;
        max-width: 594px;
        color: #ffffff;
        line-height: 22px;
        z-index: 2;

        h1 {
          color: #ffffff;
          display: inline;
          background: #30be76;
          line-height: 60px;
        }

        h4 {
          display: inline;
          background: #30be76;
        }

        a {
          height: 58px;
          width: 166px;
          border-radius: 4px;
          text-decoration: none;

          &.contact {
            background-color: #ffffff;
            color: #373645;
            border: none;
          }

          &.learn {
            background-color: transparent;
            color: #373645;
            border: 2px solid;
            border-color: #ffffff;
            color: #ffffff;
          }
        }
      }
    }

    .landingImage {
      position: absolute;
      right: 0px;
      bottom: -70px;
      z-index: 1;
    }
  }

  .features {
    overflow: hidden;
    padding-bottom: 180px;

    .topBg {
      position: absolute;
      top: -578px;
      right: 6%;
    }

    .bottomBg {
      position: absolute;
      bottom: -398px;
      left: -3%;
    }

    .featuresContent {
      height: 100%;
      max-width: 1500px;
      padding: 0px 34px;
      margin: 0px auto;

      .featuresList {
        display: grid;
        grid-template-columns: 50% 50%;
        row-gap: 50px;

        .feature {
          color: transparentize($color: #1f2041, $amount: 0.4);

          &::before {
            content: "";
            display: block;
            height: 40px;
            width: 40px;
            background-color: #8a8a8c;
            border-radius: 8px;
            transform: rotate(45deg);
            margin-right: 35px;
          }

          h5 {
            color: #1f2041;
            margin-bottom: 2px;
          }

          .featureContent {
            max-width: 390px;
          }
        }
      }
    }
  }

  .contact {
    background-color: #d9f3e6;

    .contactContent {
      height: 100%;
      max-width: 1500px;
      padding: 50px 34px;
      margin: 0px auto;

      h3 {
        color: #125634;
      }

      .contactForm {
        background-color: #ffffff;
        max-width: 90%;
        margin: 50px auto 0px;
        border-radius: 7px;
        padding: 50px 40px;

        .formLine {
          input,
          textarea {
            border: 1px solid #30be76;
            height: 58px;
            // width: calc(50% - 10px);
            border-radius: 3px;
            padding: 0px 24px;

            &:nth-child(1) {
              margin-right: 10px;
            }

            &:nth-child(2) {
              margin-left: 10px;
            }
          }

          textarea {
            height: initial;
            resize: none;
            line-height: 24px;
          }
        }

        .sendButton {
          background-color: #30be76;
          color: #fff;
          height: 58px;
          width: 200px;
          border: none;
          border-radius: 3px;

          &:disabled {
            background-color: #30be7654;
            cursor: no-drop;
          }
        }
      }
    }
  }

  .footer {
    background-color: #30be76;

    .footerContent {
      height: 100%;
      max-width: 1500px;
      padding: 60px 34px;
      margin: 0px auto;
      color: #fff;

      .emailLink {
        color: #fff;
        text-decoration: none;
        display: block;
        margin-top: 4px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .app {
    .topBanner {
      .bannerContent {
        flex-flow: column;

        .bannerSec {
          width: 100%;

          &:nth-child(1) {
            border-right: none;
            border-bottom: 2px solid #fff;
            padding-bottom: 10px;
            margin-bottom: 12px;
          }

          .offerDetails {
            margin-top: 0px !important;

            &.open {
              margin-top: 18px !important;
            }
          }

          h5 {
            font-size: 14px;
          }
        }
      }

      .showMore {
        margin: 8px auto 0px;
      }
    }
    .header {
      height: 64px;

      svg {
        height: 34px;
        margin: 0px auto;
        margin-top: 6px;
      }

      .navBtns {
        display: none;
      }
    }

    .landing {
      height: 660px;

      .landingContent {
        flex-flow: column;
        padding-top: 36px;

        .leftSec {
          width: 100%;

          h1 {
            font-size: 24px;
            line-height: 36px;
          }

          h4 {
            font-size: 18px;
          }

          .margin {
            margin-bottom: 30px !important;
          }

          .btnsWrpr {
            margin-top: 30px !important;

            a {
              height: 48px;
              font-size: 14px;
              // margin-right: 0px !important;

              &:nth-child(1) {
                margin-right: 7px !important;
              }
              &:nth-child(2) {
                margin-left: 7px !important;
                margin-right: 0px !important;
              }
            }
          }
        }
      }

      img.landingImage {
        width: 86%;
        bottom: -28px;
      }
    }
    .features {
      padding-bottom: 56px;

      .featuresContent {
        h2 {
          font-size: 22px;
          text-align: center;
          margin-bottom: 30px !important;
        }
        .featuresList {
          grid-template-columns: 1fr;
          row-gap: 30px;

          .feature {
            flex-flow: column;
            padding-right: 0px !important;
            text-align: center;

            &::before {
              margin-right: 0px;
              height: 24px;
              width: 24px;
              border-radius: 6px;
              margin-bottom: 16px;
            }

            h5 {
              font-size: 12px;
            }
          }
        }
      }
    }

    .contact {
      .contactContent {
        h3 {
          font-size: 22px;
        }

        .contactForm {
          max-width: 100%;
          padding: 34px 26px;

          .formLine {
            display: flex;
            flex-flow: column;

            input:nth-child(2),
            textarea {
              margin-top: 20px;
            }

            input,
            textarea {
              width: 100% !important;
              margin-right: 0px !important;
              margin-left: 0px !important;
            }

            & > div {
              width: 100% !important;
            }
          }

          .sendButton {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }

    .footer {
      .footerContent {
        img {
          width: 140px;
        }
      }
    }
  }
}
